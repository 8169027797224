// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-kontaktaoss-html-examples-js": () => import("./../../../src/pages/kontaktaoss.html/examples.js" /* webpackChunkName: "component---src-pages-kontaktaoss-html-examples-js" */),
  "component---src-pages-kontaktaoss-html-file-upload-js": () => import("./../../../src/pages/kontaktaoss.html/file-upload.js" /* webpackChunkName: "component---src-pages-kontaktaoss-html-file-upload-js" */),
  "component---src-pages-kontaktaoss-html-index-dont-use-js": () => import("./../../../src/pages/kontaktaoss.html/index_dont_use.js" /* webpackChunkName: "component---src-pages-kontaktaoss-html-index-dont-use-js" */),
  "component---src-pages-kontaktaoss-html-thanks-js": () => import("./../../../src/pages/kontaktaoss.html/thanks.js" /* webpackChunkName: "component---src-pages-kontaktaoss-html-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-find-us-page-js": () => import("./../../../src/templates/find-us-page.js" /* webpackChunkName: "component---src-templates-find-us-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-open-hours-page-js": () => import("./../../../src/templates/open-hours-page.js" /* webpackChunkName: "component---src-templates-open-hours-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

